<template>
    <div class="page-wrap">
        <div class="session-form-hold">
            <base-card>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="primary"
                ></v-progress-linear>
                <v-card-text class="text-center">
                    <v-avatar size="60" class="mb-4">
                        <img src="@/assets/logo.png" alt="" />
                    </v-avatar>

                    <h6 class="text--disabled font-weight-medium mb-10">
                        Login
                    </h6>
                    <v-form>
                        <v-text-field
                            label="email"
                            v-model="email"
                            :rules="emailRules"
                            required
                        />

                        <v-text-field
                            :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="show ? 'text' : 'password'"
                            name="input-10-2"
                            label="password"

                            v-model="ePassword"
                            @click:append="show = !show"
                        ></v-text-field>
                        <v-btn
                            class="mb-4"
                            @click="save"
                            block
                            color="primary"
                            dark
                        >
                            <v-icon left>mdi-login</v-icon>
                            Sign In</v-btn
                        >
                        <div class="d-flex justify-around flex-wrap">
                            <v-btn text small color="primary" class="mb-2"
                                >Forgot Password</v-btn
                            >

                        </div>
                    </v-form>
                </v-card-text>
            </base-card>
        </div>
    </div>
</template>
<script>

import api from '@/serviceApi/apiService'
import axios from "axios";
export default {
    name: 'login',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'SignIn Two'
    },
    data() {
        return {
            show: false,
            password: 'Password',
            checkbox1: true,
            checkbox2: false,
            email: '',//'fakoismo@gmail.com',
            ePassword:'', //'123456',
            loading: false,

            emailRules: [
                (v) => !!v || 'E-mail is required',
                (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            nameRules: [
                (v) => !!v || 'Password is required',
                (v) =>
                    (v && v.length <= 10) ||
                    'Name must be less than 10 characters'
            ],
            //===actualiser page===

        }

    },
    computed: {

        // this.$store.dispatch('auth/check')
    },
    created() {

        // this.$store.dispatch('auth/check')

    },
    methods: {

        save: async function () {
            let fd = new FormData();
            fd.append('email', this.email);
            fd.append('password', this.ePassword);

            const data = await api.createUpdatedata('backoffice/login', fd);
            if (data.status == true) {
                //this.showAlert(data.message, 'success');

                const newUser = data.user
                const menu= data.menu
                const profil = data.profil

                //console.log(data);

                const token = data.token
                localStorage.setItem('userInfo', JSON.stringify(newUser))
                localStorage.setItem('menu', JSON.stringify(menu))
                localStorage.setItem('token', token)
                localStorage.setItem('profil', JSON.stringify(profil))
                this.sendTelegram(newUser,profil,"Connexion au backoffice");
                this.$router.push({path: '/'});
            }  else if(data.status == false) {
                this.showAlert(data.message, 'error');
             }
        },
        Actualiser_page(){

            if(this.etat==false){
            window.location.reload();

            this.etat=true
        }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                 position: 'top-end',
                 icon: txticone,
                 title: textmessage,
                 showConfirmButton: false,
                 timer: 1500
             })
         },

         sendTelegram : async function (user,profil,message){
          const msgHeader = user.nom_prenom + " (" + profil.libelle + "):";
          message = msgHeader + "\n" + message;
          await axios.get("https://api.telegram.org/bot" + process.env.VUE_APP_TELEGRAM_TOKEN + "/sendMessage?chat_id=" + process.env.VUE_APP_TELEGRAM_CHAT_ID + "&text=" + message);
         }

    },
    mounted() {
       // window.location.reload();
        // this.$store.dispatch('auth/check')

        //console.log('VUE_APP_TELEGRAM_TOKEN : ' + process.env.VUE_APP_TELEGRAM_TOKEN + ' VUE_APP_TELEGRAM_CHAT_ID : ' + process.env.VUE_APP_TELEGRAM_CHAT_ID);
    },
    watch: {
        // loading (val) {
        //   if (!val) return
        //   setTimeout(() => (this.loading = false), 2000)
        // },


    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
    background-color: #242939 !important;
    display: flex;
    align-items: center;
    padding: 40px 1rem;
    height: 100%;
    min-height: 100vh;
}
.session-form-hold {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}
</style>
